
.footer , .footer a{
    background-color: #830609;
    color: #efefef;
}
.footer h3{
    margin-bottom: 10px;
}
.footer-1 {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding: 20px;
}
.whatsapp svg{
    width: 16px;
    height: 16px;
    margin-right: 10px;
}
.whatsapp span{
    margin-right: 10px;
}
.redes-sociales {
    font-size: 32px;
    display: flex;
    gap: 5px;
}

.footer-2 {
    padding: 20px;
}

.comer {
    font-size: 32px;
    padding-bottom: 30px;
}

.direccion {
    padding-bottom: 43px;
}

.horario {
    padding-bottom: 43px;
}

.ayuda {
    padding-bottom: 43px ;
}

.footer-3 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 10px;
}

.copyright  {
    text-align: center;
} 

.zinc {
    border-radius: 2px;
    border: none;
    padding: 3px 8px;
    color: #830609;
}

/* hr {
    border: none;
    border-style: inset;
    border-width: 1px;
    border-color: rgb(255, 255, 255);
} */

.geronimo {
    height: 1200px;
}
/*-------Desktop--------*/

@media (min-width: 1200px) {
    .footer{
        max-width: 100vw;
        margin-bottom: 0px;
    }
    .footer-1 {
        display: flex;
        justify-content: space-between;
        font-size: 24px;
        padding-left: 3%;
    }

    .redes-sociales {
        font-size: 45px;
        display: flex;
        gap: 5px;
    }

    .footer-2 {
        padding-left: 10px;
    }

    .informacion {
        display: flex;
        justify-content: space-around;
    }
    
    .comer {
        font-size: 64px;
    }
    
    .direccion {
        width: 176px;
    }
    
    .horario {        
        width: 180px;
    }
    
    .lun-vie {
        padding-bottom: 20px;
    }
    
    .div-1 {
        padding-top: 15px;
    }
    
    .footer-3 {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        padding: 11px;
    }
    
    .copyright {
        display: flex;
        justify-content: space-around;
    }

    .centro {
        padding-right: 20px;
    }
}
.conocenos a{
    color: white;
}
.div1 img{
    width: 150px;
}